
class Colors {

    static statusColor(key) {
        return [
             'bg-yellow-100 text-yellow-800', 'bg-green-100 text-green-800', 'bg-blue-100 text-blue-800', 'bg-yellow-500 text-yellow-800', 'bg-pink-200 text-pink-800', 'bg-green-800 text-green-100', 'bg-pink-600 text-pink-100', 'bg-yellow-800 text-yellow-100', 'bg-red-400 text-red-900'
        ][key]
    }
    static serviceStatusColor(key) {
        return [
            'bg-gray-100 text-gray-800', 'bg-yellow-100 text-yellow-800', 'bg-yellow-500 text-yellow-800', 'bg-blue-100 text-blue-800', 'bg-green-100 text-green-800', 'bg-purple-200 text-purple-800', 'bg-green-800 text-green-100'
        ][key]
    }

    static typeColor(key){
        return ['bg-pink-600 text-pink-100', 'bg-yellow-800 text-yellow-100'][key]
    }

}

export default Colors
