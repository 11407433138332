<template>
  <item-text title="Statuss">
    <template v-slot:content>
      <Badge :text="item.status.name" :className="`${statusColor(item.status)} mr-2 mb-2 md:mb-0`" />
    </template>
  </item-text>

  <item-text title="Pasūtījuma nr." :text="item.uuid" />
  <item-text title="Pasūtījuma datums" :text="item.order_date_formatted" />
  <template v-if="item.author">
    <item-text title="Pasūtījumu izveidojis" :text="item.author.name" />
  </template>

  <template v-if="item.managing_user">
    <item-text title="Menedžeris" :text="item.managing_user.name" />
  </template>

  <template v-if="item.managing_branch">
    <item-text title="Apkalpojošā filiāle" :text="item.managing_branch.name" />
  </template>

  <div class="w-full flex flex-wrap my-2 items-start">
    <item-text title="Klients">
      <template v-slot:content>
        <router-link :to="`/customers/${item.customer.id}`" class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0 hover:text-main4 dark:hover:text-main4">
          {{item.customer.name}}
        </router-link>
      </template>
    </item-text>

    <template v-if="item.branch">
      <item-text title="Klienta filiāle" :text="item.branch.name" />
    </template>

    <item-text title="Valsts" :text="`${item.country ? item.country.name : 'Nav norādīta'}`" />

    <item-text title="Valoda" :text="item.lang" />

    <template v-if="item.fulfilled_by">
      <item-text title="Apstrādā" :text="item.fulfilled_by.name" />
    </template>
  </div>

  <div class="w-full flex flex-wrap items-start">
      <template v-if="item.shipping_note">
          <item-text title="Pārvadātāja info, AM info" :text="item.shipping_note" />
      </template>
      <template v-if="item.shipping_terms">
          <item-text title="Piegādes noteikumi" :text="item.shipping_terms" />
      </template>
    <template v-if="item.shipping_method">
      <item-text title="Piegādes veids" :text="item.shipping_method" />
    </template>
    <template v-if="item.shipping_cost">
      <item-text title="Piegādes izmaksas" :text="item.shipping_cost" />
    </template>
    <template v-if="item.shipping_price">
      <item-text title="Piegādes cena klientam" :text="item.shipping_price" />
    </template>
    <template v-if="item.tracking_nr">
      <item-text title="Tracking nr." :text="item.tracking_nr" />
    </template>
  </div>


  <div class="w-full flex flex-wrap my-2 items-start">

    <template v-if="item.order_items_count > 0">
      <item-text title="Pozīcijas" :text="item.order_items_count" />
      <item-text title="Kopā EUR" :text="item.total" />
      <item-text title="PVN EUR" :text="item.vat" />
      <item-text title="Summa EUR" :text="item.total_with_vat" />
      <item-text title="Reversais PVN" :text="item.reverse_vat ? 'Jā' : 'Nē'" />

    </template>

    <template v-if="item.notes">
      <div class="w-full mt-5">
        <item-text title="Piezīmes" :text="item.notes" />
      </div>
    </template>

      <template v-if="item.internal_notes">
          <div class="w-full mt-5">
              <item-text title="Iekšējās piezīmes" :text="item.internal_notes" />
          </div>
      </template>


  </div>

</template>

<script>
import { mapGetters } from "vuex";
import ItemText from "@/components/Components/ItemText"
import Colors from "@/services/Helpers/colors";
import Badge from "@/components/Components/Badge"
import Validation from "@/modules/Validation";

export default {
  name: "ShowOrderDetails",
  components: {
    ItemText,
    Badge,
  },
  data() {
    return {
      form: null,
      showEditAcceptanceActForm: false,
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors"
    }),
    formValidation() {
      return {
      }
    }
  },
  methods: {
    hideEditAcceptanceActForm(){
      this.showEditAcceptanceActForm = false
        this.$store.dispatch('getSingleOrder', this.$route.params.orderId)
    },

    statusColor(status) {
        let key = status ? status.id : 0
        return Colors.statusColor(key)
    },
    typeColor(id){
        let key = id - 1
        return Colors.typeColor(key)
    },
    openCourierApplication() {
        let routeData = this.$router.resolve({ path: '/courier_applications/' + this.item.courier_application.id});
        window.open(routeData.href, '_blank');
    },
    downloadAcceptanceAct(){
        this.$store.dispatch('getOrderAcceptanceActPdf', this.item.acceptance_act)
    },
    editAcceptanceAct() {
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateOrderAcceptanceAct', {
            orderId: this.item.id,
            documentId: this.item.acceptance_act.id,
            data: {
                notes: this.form.notes,
                start_date: this.form.start_date,
            }
        }).then(response => {
            this.showEditAcceptanceActForm = false
        })
      }
    }
  },
  mounted() {
    if (this.item.acceptance_act) {
      this.form = {
        notes: this.item.acceptance_act.notes,
        start_date: this.item.acceptance_act.start_date,
      }
    }
  }
}
</script>

<style>

</style>